import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'src/hooks';
import { detailsBoxStyles } from './styles';

const DetailsBox = () => {
  const theme = useTheme();

  const deskSelectedState = useAppSelector(
    (state) => state.booking.deskSelected.deskSelected,
  );

  const DetailsItem = ({
    title,
    data,
  }: {
    title: string;
    data: string | number;
  }): JSX.Element => (
    <Typography>
      {title}
      <Box component="span" sx={detailsBoxStyles(theme).detailsValue}>
        {data}
      </Box>
    </Typography>
  );

  return (
    <Box sx={detailsBoxStyles(theme).reservationContainer}>
      <Box sx={detailsBoxStyles(theme).detailsContainer}>
        {deskSelectedState ? (
          <>
            <Typography
              sx={detailsBoxStyles(theme).detailsTitle}
            >{`Bureau ${deskSelectedState.name}`}</Typography>
            <Divider />
            <Box sx={detailsBoxStyles(theme).detailsInfos}>
              {deskSelectedState.booking_status.is_available && (
                <Typography>Disponible</Typography>
              )}
              {deskSelectedState.booking_status.reserved_by && (
                <DetailsItem
                  title="Réservé par : "
                  data={deskSelectedState.booking_status.reserved_by.username}
                />
              )}
              <DetailsItem
                title="Nombre d'écran : "
                data={deskSelectedState.number_of_monitors}
              />
              <DetailsItem
                title="Support d'écran : "
                data={deskSelectedState.has_laptop_support ? 'Oui' : 'Non'}
              />
              <DetailsItem
                title="Possède un dock : "
                data={deskSelectedState.has_dock ? 'Oui' : 'Non'}
              />
              <DetailsItem
                title="Souris : "
                data={deskSelectedState.has_mouse ? 'Oui' : 'Non'}
              />
              <DetailsItem
                title="Clavier : "
                data={deskSelectedState.has_keyboard ? 'Oui' : 'Non'}
              />
              <DetailsItem
                title="Type d'écran : "
                data={deskSelectedState.monitor_configuration_display}
              />
              {deskSelectedState.special_attention !== '' && (
                <DetailsItem
                  title="Autre : "
                  data={deskSelectedState.special_attention}
                />
              )}
            </Box>
          </>
        ) : (
          <Typography sx={detailsBoxStyles(theme).detailsInfos}>
            Pas de bureau sélectionné.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DetailsBox;
