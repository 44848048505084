import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

interface DetailsBoxStyles {
  reservationContainer: SxProps;
  detailsContainer: SxProps;
  detailsTitle: SxProps;
  detailsInfos: SxProps;
  detailsValue: SxProps;
}

export const detailsBoxStyles = (theme: Theme): DetailsBoxStyles => ({
  reservationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    minHeight: '35vh',
    justifyContent: 'space-between',
    [theme.breakpoints.only('sm')]: {
      gridColumn: 'span 2',
    },
  },

  detailsContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
  },

  detailsTitle: {
    padding: theme.spacing(1),
  },

  detailsInfos: {
    padding: theme.spacing(1),
  },

  detailsValue: {
    fontWeight: 300,
  },
});
