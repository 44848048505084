import { createSlice } from '@reduxjs/toolkit';
import { ReduxStatus } from 'src/utils/types/reduxStatusValues';
import { UploadUserImageRequest } from '../types';
import { uploadUserImageAsync } from '../authAsync';

const initialState: UploadUserImageRequest = {
  status: ReduxStatus.Idle,
  error: null,
  alert: {
    successMessage: '',
    errorMessage: '',
  },
  data: {
    file: '',
  },
};

const uploadUserImageSlice = createSlice({
  name: 'uploadUserImage',
  initialState,
  reducers: {
    resetUploadUserImageRequest: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadUserImageAsync.pending, (state: { status: string }) => {
        state.status = ReduxStatus.Loading;
      })
      .addCase(uploadUserImageAsync.fulfilled, (state, action) => {
        state.status = ReduxStatus.Succeeded;
        state.alert.successMessage = 'uploadUserImage successful';
        state.data = action.payload;
      })
      .addCase(uploadUserImageAsync.rejected, (state, action) => {
        state.status = ReduxStatus.Failed;
        state.error = action.error.message;
        state.alert.errorMessage = 'uploadUserImage failed';
      });
  },
});

export const { resetUploadUserImageRequest } = uploadUserImageSlice.actions;

export default uploadUserImageSlice.reducer;
