import {
  Alert,
  Box,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import sx from 'mui-sx';

import { landingPageStyles } from './styles';
import { useAppSelector } from 'src/hooks';
import CDatePicker from 'src/components/UI/CDatePicker/CDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import DeskGrid from 'src/components/UI/DeskComponents/DeskGrid';
import {
  createBookingAsync,
  deleteBookingAsync,
  getDeskAsync,
  getOfficeSpaceAsync,
} from 'src/store/booking/bookingAsync';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store/store';
import { ReduxStatus } from 'src/utils/types/reduxStatusValues';
import CCircularProgress from 'src/components/UI/CCircularProgress/CCircularProgress';
import { setDeskSelected } from 'src/store/booking/bookingSlices/deskSelectedSlice';
import {
  resetGetDeskRequest,
  updateStatusDeskList,
} from 'src/store/booking/bookingSlices/getDeskSlice';
import { resetCreateBookingRequest } from 'src/store/booking/bookingSlices/createBookingSlice';
import { resetDeleteBookingRequest } from 'src/store/booking/bookingSlices/deleteBookingSlice';
import { LoadingButton } from '@mui/lab';
import CSelect from 'src/components/UI/CSelect/CSelect';
import { OfficeSpace } from 'src/utils/types/OfficeSpace';
import { menuItem } from 'src/components/UI/types';
import useIsTabletPortait from 'src/hooks/useIsTablet';
import HelpBox from './HelpBox/HelpBox';
import DetailsBox from './DetailsBox/DetailsBox';

const LandingPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const isTablet = useIsTabletPortait();

  const username = useAppSelector(
    (state) => state.auth.userInfos.data.username,
  );
  const profileImage = useAppSelector(
    (state) => state.auth.userInfos.data.profile_image,
  );
  const deskSelectedState = useAppSelector(
    (state) => state.booking.deskSelected.deskSelected,
  );
  const getDeskRequest = useAppSelector((state) => state.booking.getDesk);
  const createBookingRequest = useAppSelector(
    (state) => state.booking.createBooking,
  );
  const deleteBookingRequest = useAppSelector(
    (state) => state.booking.deleteBooking,
  );
  const getOfficeSpaceRequest = useAppSelector(
    (state) => state.booking.getOfficeSpace,
  );

  const [daySelected, setDaySelected] = useState<Dayjs | null>(dayjs());
  const [officeSelected, setOfficeSelected] = useState<OfficeSpace | null>(
    null,
  );
  const [officeSelectedName, setOfficeSelectedName] = useState<string>('');
  const [officeOptions, setOfficeOptions] = useState<menuItem[]>([]);
  const [isReversed, setIsReversed] = useState(false);

  const handleSymmetryToggle = () => {
    setIsReversed((prev) => !prev);
  };

  const handleOfficeChange = (event: SelectChangeEvent) => {
    const selectedOffice = getOfficeSpaceRequest.data.find(
      (office: OfficeSpace) => office.name === event.target.value,
    );

    if (selectedOffice) {
      setOfficeSelectedName(selectedOffice.name);
      setOfficeSelected(selectedOffice);
    }
  };

  useEffect(() => {
    dispatch(resetGetDeskRequest());
    dispatch(getOfficeSpaceAsync());
  }, []);

  useEffect(() => {
    if (
      getOfficeSpaceRequest.status === ReduxStatus.Succeeded &&
      getOfficeSpaceRequest.data.length > 0 &&
      officeSelected !== null
    ) {
      dispatch(
        getDeskAsync({
          booking_date: daySelected?.format('YYYY-MM-DD'),
          office_space: officeSelected.id,
        }),
      );
    }
  }, [daySelected, officeSelected]);

  useEffect(() => {
    if (getOfficeSpaceRequest.status === ReduxStatus.Succeeded) {
      setOfficeOptions(
        getOfficeSpaceRequest.data.map(
          (office: OfficeSpace, index: number) => ({
            id: index,
            label: office.name,
          }),
        ),
      );
      if (getOfficeSpaceRequest.data.length > 0) {
        setOfficeSelected(getOfficeSpaceRequest.data[0]);
        setOfficeSelectedName(getOfficeSpaceRequest.data[0].name);
      }
    }
  }, [getOfficeSpaceRequest]);

  useEffect(() => {
    if (createBookingRequest.status === ReduxStatus.Succeeded) {
      dispatch(
        updateStatusDeskList({
          id: deskSelectedState?.id,
          status: false,
          name: username,
          reservation_id: createBookingRequest.data.id,
          image: profileImage,
        }),
      );
      dispatch(setDeskSelected(null));
      dispatch(resetCreateBookingRequest());
    }
  }, [createBookingRequest]);

  useEffect(() => {
    if (deleteBookingRequest.status === ReduxStatus.Succeeded) {
      dispatch(
        updateStatusDeskList({
          id: deskSelectedState?.id,
          status: true,
        }),
      );
      dispatch(setDeskSelected(null));
      dispatch(resetDeleteBookingRequest());
    }
  }, [deleteBookingRequest]);

  return (
    <Box sx={landingPageStyles(theme).mainWrapper}>
      <Box
        sx={sx(
          landingPageStyles(theme).cardContainer,
          landingPageStyles(theme).infosContainer,
        )}
      >
        <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>
          {daySelected?.locale('fr').format('dddd D MMMM YYYY')}
        </Typography>
        <HelpBox setIsReversed={handleSymmetryToggle} />
        <Box sx={landingPageStyles(theme).infosText}>
          {!isTablet && (
            <Box sx={landingPageStyles(theme).gridDeskWrapper}>
              {getDeskRequest.status === ReduxStatus.Loading && (
                <CCircularProgress isModal sx={{ height: '100%' }} />
              )}
              {getDeskRequest.status === ReduxStatus.Succeeded && (
                <>
                  {getDeskRequest.data.length > 0 && (
                    <DeskGrid
                      data={getDeskRequest.data}
                      isReversed={isReversed}
                    />
                  )}
                  {getDeskRequest.data.length === 0 && (
                    <Box>
                      <Alert severity="warning">
                        {
                          "Vous n'avez pas accès aux ressources demandées. Veuillez contacter votre administrateur."
                        }
                      </Alert>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
          <Box sx={landingPageStyles(theme).selectWrapper}>
            <CSelect
              label="Office space"
              labelId="office-space"
              onChange={handleOfficeChange}
              menuItems={officeOptions}
              value={officeSelectedName}
              disabled={officeOptions.length <= 1}
            />
            <CDatePicker
              value={daySelected}
              setValue={setDaySelected}
              label="Date"
              hasTodayButton
              minDate={dayjs()}
              maxDate={dayjs().add(6, 'day')}
              hasNavigation
            />
            {isTablet && (
              <Box sx={landingPageStyles(theme).gridDeskWrapper}>
                {getDeskRequest.status === ReduxStatus.Loading && (
                  <CCircularProgress isModal sx={{ height: '100%' }} />
                )}
                {getDeskRequest.status === ReduxStatus.Succeeded && (
                  <>
                    {getDeskRequest.data.length > 0 && (
                      <DeskGrid
                        data={getDeskRequest.data}
                        isReversed={isReversed}
                      />
                    )}
                    {getDeskRequest.data.length === 0 && (
                      <Box>
                        <Alert severity="warning">
                          {
                            "Vous n'avez pas accès aux ressources demandées. Veuillez contacter votre administrateur."
                          }
                        </Alert>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
            <DetailsBox />
          </Box>
          <Box sx={landingPageStyles(theme).reservationButton}>
            <LoadingButton
              loading={deleteBookingRequest.status === ReduxStatus.Loading}
              disabled={
                !deskSelectedState ||
                (deskSelectedState &&
                  deskSelectedState.booking_status.is_available) ||
                (deskSelectedState.booking_status.reserved_by &&
                  deskSelectedState.booking_status.reserved_by.username !==
                    username) ||
                deskSelectedState.booking_status.reserved_by === null
              }
              variant="contained"
              onClick={() =>
                dispatch(
                  deleteBookingAsync(
                    deskSelectedState!.booking_status.reserved_id,
                  ),
                )
              }
            >
              Annuler
            </LoadingButton>
            <LoadingButton
              loading={createBookingRequest.status === ReduxStatus.Loading}
              disabled={
                !deskSelectedState ||
                (deskSelectedState &&
                  !deskSelectedState.booking_status.is_available) ||
                (getDeskRequest.data.length > 0 &&
                  getDeskRequest.data.filter(
                    (item) =>
                      item.booking_status.reserved_by?.username === username,
                  ).length > 0)
              }
              variant="contained"
              onClick={() =>
                dispatch(
                  createBookingAsync({
                    booking_date: daySelected!.format('YYYY-MM-DD'),
                    desk: deskSelectedState!.id,
                  }),
                )
              }
            >
              Réserver
            </LoadingButton>
          </Box>
        </Box>
        {(getDeskRequest.status === ReduxStatus.Failed ||
          createBookingRequest.status === ReduxStatus.Failed) && (
          <Alert severity="error">
            Une erreur est survenue veuillez réessayer plus tard.
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default LandingPage;
