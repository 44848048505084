import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

interface LandingPageStyles {
  mainWrapper: SxProps;
  cardContainer: SxProps;
  gridDeskWrapper: SxProps;
  title: SxProps;
  username: SxProps;
  infosContainer: SxProps;
  infosTitle: SxProps;
  infosText: SxProps;
  reservationButton: SxProps;
  selectWrapper: SxProps;
}

export const landingPageStyles = (theme: Theme): LandingPageStyles => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },

  cardContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
  },

  gridDeskWrapper: {
    gridColumn: 'span 2',
    height: '100%',
  },

  title: {
    fontWeight: 500,
    textAlign: 'center',
    marginBlock: theme.spacing(3),
  },

  username: {
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.primary.main,
  },

  infosContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  infosTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
  },

  infosText: {
    display: 'grid',
    width: '100%',
    gap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  reservationButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    gridColumn: 'span 3',
  },

  selectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
});
