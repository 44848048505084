import { createSlice } from '@reduxjs/toolkit';
import { ReduxStatus } from 'src/utils/types/reduxStatusValues';
import { createBookingAsync } from '../bookingAsync';
import { CreateBookingRequest } from '../types';

const initialState: CreateBookingRequest = {
  status: ReduxStatus.Idle,
  error: null,
  alert: {
    successMessage: '',
    errorMessage: '',
  },
  data: {
    id: 0,
    booking_date: '',
    created_at: '',
    updated_at: '',
    office_space: 0,
    desk: 0,
  },
};

const createBookingSlice = createSlice({
  name: 'createBooking',
  initialState,
  reducers: {
    resetCreateBookingRequest: (state) => {
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBookingAsync.pending, (state: { status: string }) => {
        state.status = ReduxStatus.Loading;
      })
      .addCase(createBookingAsync.fulfilled, (state, action) => {
        state.status = ReduxStatus.Succeeded;
        state.alert.successMessage = 'createBooking successful';
        state.data = action.payload;
      })
      .addCase(createBookingAsync.rejected, (state, action) => {
        state.status = ReduxStatus.Failed;
        state.error = action.error.message;
        state.alert.errorMessage = 'createBooking failed';
      });
  },
});

export const { resetCreateBookingRequest } = createBookingSlice.actions;

export default createBookingSlice.reducer;
