import { SxProps, Theme } from '@mui/material';

interface ContainerProps {
  availability: boolean;
  selected: boolean;
  isUser: boolean;
}

interface DeskComponentsStyles {
  // eslint-disable-next-line no-unused-vars
  container: ({ availability, selected, isUser }: ContainerProps) => SxProps;
  // eslint-disable-next-line no-unused-vars
  wrapper: (isReversed: boolean) => SxProps;
  // eslint-disable-next-line no-unused-vars
  gridWrapper: (isReversed: boolean) => SxProps;
  // eslint-disable-next-line no-unused-vars
  containerGrid: (maxColumn: number) => SxProps;
}

export const deskComponentsStyles = (theme: Theme): DeskComponentsStyles => ({
  container: ({ availability, selected, isUser }: ContainerProps) => ({
    width: '100%',
    minWidth: '20px',
    backgroundColor:
      theme.palette[isUser ? 'info' : availability ? 'success' : 'error'][
        selected ? 'light' : 'main'
      ],
    '&:hover': {
      backgroundColor:
        theme.palette[isUser ? 'info' : availability ? 'success' : 'error']
          .light,
    },
    color: theme.palette.text.primary,
    '& img': {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  }),

  wrapper: (isReversed: boolean) => ({
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: isReversed ? 'row-reverse' : 'row',
  }),

  gridWrapper: (isReversed: boolean) => ({
    display: 'flex',
    flexDirection: isReversed ? 'column-reverse' : 'column',
    '& > :nth-of-type(even)': {
      marginBottom: isReversed ? '1px' : theme.spacing(2),
    },
    '& > :nth-of-type(odd)': {
      marginBottom: isReversed ? theme.spacing(1) : '1px',
    },
    width: '100%',
  }),

  containerGrid: (maxColumn: number) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${maxColumn}, 1fr)`,
    gap: '1px',
  }),
});
