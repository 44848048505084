import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

interface HelpBoxStyles {
  mainWrapper: SxProps;
}

export const helpBoxStyles = (theme: Theme): HelpBoxStyles => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
});
