import axios from 'axios';
import { BACK_URL } from 'src/config';
import { store } from '../store';
import { CreateBookingPayload } from './types';
import { Booking } from 'src/utils/types/Booking';
import { Desk } from 'src/utils/types/Desk';
import { OfficeSpace } from 'src/utils/types/OfficeSpace';

export const createBooking = async (
  payload: CreateBookingPayload,
): Promise<Booking> => {
  const { token } = store.getState().auth.login;
  const response = await axios.post<Booking>(
    `${BACK_URL}/api/bookings/`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const deleteBooking = async (id: number | null) => {
  const { token } = store.getState().auth.login;
  const response = await axios.delete(`${BACK_URL}/api/bookings/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const getDesks = async (
  id?: number,
  booking_date?: string,
  column?: number,
  has_dock?: boolean,
  has_keyboard?: boolean,
  has_laptop_support?: boolean,
  has_mouse?: boolean,
  monitor_configuration?:
    | 'CURVED_WIDE_SCREEN'
    | 'SMALL_MONITOR'
    | 'WIDE_SCREEN',
  number_of_monitors?: number,
  office_space?: number,
  row?: number,
): Promise<Desk[]> => {
  const { token } = store.getState().auth.login;
  const queryParams = new URLSearchParams();

  if (booking_date) {
    queryParams.append('booking_date', booking_date);
  }
  if (column) {
    queryParams.append('column', column.toString());
  }
  if (has_dock) {
    queryParams.append('has_dock', has_dock.toString());
  }
  if (has_keyboard) {
    queryParams.append('has_keyboard', has_keyboard.toString());
  }
  if (has_laptop_support) {
    queryParams.append('has_laptop_support', has_laptop_support.toString());
  }
  if (has_mouse) {
    queryParams.append('has_mouse', has_mouse.toString());
  }
  if (monitor_configuration) {
    queryParams.append(
      'monitor_configuration',
      monitor_configuration.toString(),
    );
  }
  if (number_of_monitors) {
    queryParams.append('number_of_monitors', number_of_monitors.toString());
  }
  if (office_space) {
    queryParams.append('office_space', office_space.toString());
  }
  if (row) {
    queryParams.append('row', row.toString());
  }

  const url = id
    ? `${BACK_URL}/api/desks/${id}/?${queryParams.toString()}`
    : `${BACK_URL}/api/desks/?${queryParams.toString()}`;

  const response = await axios.get<Desk[]>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getOfficeSpace = async (
  id?: number,
  capacity?: number,
  floor?: number,
  name?: string,
  number_of_employees?: number,
  service?: number,
): Promise<OfficeSpace[]> => {
  const { token } = store.getState().auth.login;
  const queryParams = new URLSearchParams();

  if (capacity) {
    queryParams.append('capacity', capacity.toString());
  }
  if (floor) {
    queryParams.append('floor', floor.toString());
  }
  if (name) {
    queryParams.append('name', name);
  }
  if (number_of_employees) {
    queryParams.append('number_of_employees', number_of_employees.toString());
  }
  if (service) {
    queryParams.append('service', service.toString());
  }

  const url = id
    ? `${BACK_URL}/api/office-spaces/${id}/?${queryParams.toString()}`
    : `${BACK_URL}/api/office-spaces/?${queryParams.toString()}`;

  const response = await axios.get<OfficeSpace[]>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
