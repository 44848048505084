import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Desk } from 'src/utils/types/Desk';

interface DeskSelectedState {
  deskSelected: Desk | null;
}

const initialState: DeskSelectedState = {
  deskSelected: null,
};

const deskSelectedSlice = createSlice({
  name: 'desk',
  initialState,
  reducers: {
    setDeskSelected: (state, action: PayloadAction<Desk | null>) => {
      if (state.deskSelected !== action.payload) {
        state.deskSelected = action.payload;
      } else {
        state.deskSelected = null;
      }
    },
  },
});

export const { setDeskSelected } = deskSelectedSlice.actions;

export default deskSelectedSlice.reducer;
