import { Alert, Box, Typography, useTheme } from '@mui/material';

import { settingsPageStyles } from './styles';
import { useAppSelector } from 'src/hooks';
import CImportTextfield from 'src/components/UI/CImportTextfield/CImportTextfield';
import { useEffect, useState } from 'react';
import { ReduxStatus } from 'src/utils/types/reduxStatusValues';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store/store';
import { resetUploadUserImageRequest } from 'src/store/auth/authSlices/uploadUserImageSlice';
import { uploadUserImageAsync } from 'src/store/auth/authAsync';
import { updateProfileImage } from 'src/store/auth/authSlices/userInfosSlice';

const AvatarSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const user = useAppSelector((state) => state.auth.userInfos.data);
  const uploadImageRequest = useAppSelector(
    (state) => state.auth.uploadUserImage,
  );

  const [imageName, setImageName] = useState<File | ''>('');
  const [image, setImage] = useState<File | undefined>();

  useEffect(() => {
    dispatch(resetUploadUserImageRequest());
  }, []);

  useEffect(() => {
    if (uploadImageRequest.status === ReduxStatus.Succeeded) {
      dispatch(updateProfileImage({ image: uploadImageRequest.data }));
      setImage(undefined);
      setImageName('');
    }
  }, [uploadImageRequest]);

  return (
    <Box sx={settingsPageStyles(theme).avatarWrapper}>
      <Typography variant="h4">{"Changement d'avatar"}</Typography>
      <Box sx={settingsPageStyles(theme).importAvatarWrapper}>
        <img src={user.profile_image} />
        <CImportTextfield
          setValue={setImageName}
          value={imageName}
          setFile={setImage}
          onChange={() => dispatch(resetUploadUserImageRequest())}
          isLoading={uploadImageRequest.status === ReduxStatus.Loading}
          accept={'.png,.jpg'}
          importFunction={() => {
            try {
              if (!image) {
                return;
              } else {
                dispatch(uploadUserImageAsync(image));
              }
            } catch (errorUpload) {
              throw new Error("Une erreur est survenue lors de l'upload.");
            }
          }}
        />
      </Box>
      {uploadImageRequest.status === ReduxStatus.Succeeded && (
        <Alert severity="success">Votre avatar a bien été mis à jour.</Alert>
      )}
      {uploadImageRequest.status === ReduxStatus.Failed && (
        <Alert severity="error">
          {"Une erreur est survenue lors de la mise à jour de l'avatar"}
        </Alert>
      )}
    </Box>
  );
};

export default AvatarSection;
