import { createSlice } from '@reduxjs/toolkit';
import { ReduxStatus } from 'src/utils/types/reduxStatusValues';
import { getOfficeSpaceAsync } from '../bookingAsync';
import { GetOfficeSpaceRequest } from '../types';

const initialState: GetOfficeSpaceRequest = {
  status: ReduxStatus.Idle,
  error: null,
  alert: {
    successMessage: '',
    errorMessage: '',
  },
  data: [],
};

const getOfficeSpaceSlice = createSlice({
  name: 'getOfficeSpace',
  initialState,
  reducers: {
    resetGetOfficeSpaceRequest: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfficeSpaceAsync.pending, (state: { status: string }) => {
        state.status = ReduxStatus.Loading;
      })
      .addCase(getOfficeSpaceAsync.fulfilled, (state, action) => {
        state.status = ReduxStatus.Succeeded;
        state.alert.successMessage = 'getOfficeSpace successful';
        state.data = action.payload;
      })
      .addCase(getOfficeSpaceAsync.rejected, (state, action) => {
        state.status = ReduxStatus.Failed;
        state.error = action.error.message;
        state.alert.errorMessage = 'getOfficeSpace failed';
      });
  },
});

export const { resetGetOfficeSpaceRequest } = getOfficeSpaceSlice.actions;

export default getOfficeSpaceSlice.reducer;
