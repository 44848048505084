import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { deskComponentsStyles } from './styles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store/store';
import { setDeskSelected } from 'src/store/booking/bookingSlices/deskSelectedSlice';
import { useAppSelector } from 'src/hooks';
import { ReactNode } from 'react';
import { Desk } from 'src/utils/types/Desk';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import MonitorIcon from '@mui/icons-material/Monitor';
import useIsTablet from 'src/hooks/useIsTablet';
import { v4 as uuidv4 } from 'uuid';

type DeskComponentProps = {
  data: Desk;
};

const DeskComponent = ({ data }: DeskComponentProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isTablet = useIsTablet();

  const username = useAppSelector(
    (state) => state.auth.userInfos.data.username,
  );

  const deskSelectedState = useAppSelector(
    (state) => state.booking.deskSelected.deskSelected,
  );

  const HandleMonitorIcon = (): ReactNode => {
    const icons: ReactNode[] = [];

    for (let i = 0; i < data.number_of_monitors; i++) {
      let iconDisplayed;

      switch (data.monitor_configuration) {
        case 'CURVED_WIDE_SCREEN':
          iconDisplayed = (
            <RemoveFromQueueIcon
              key={`screen-${uuidv4()}`}
              sx={{ fontSize: isTablet ? '1rem' : '1.5rem' }}
            />
          );
          break;
        case 'WIDE_SCREEN':
          iconDisplayed = (
            <ScreenshotMonitorIcon
              key={`screen-${uuidv4()}`}
              sx={{ fontSize: isTablet ? '1rem' : '1.5rem' }}
            />
          );
          break;
        default:
          iconDisplayed = (
            <MonitorIcon
              key={`screen-${uuidv4()}`}
              sx={{ fontSize: isTablet ? '1rem' : '1.5rem' }}
            />
          );
      }

      icons.push(iconDisplayed);
    }

    return <>{icons}</>;
  };

  const DeskIcon = (): ReactNode => {
    if (data.booking_status.reserved_by) {
      return <img src={data.booking_status.reserved_by.profile_image} />;
    } else {
      return <>{HandleMonitorIcon()}</>;
    }
  };

  const TooltipInformations = (): ReactNode => (
    <Box>
      <Typography>{data.name}</Typography>
      {data.booking_status.is_available ? (
        <Typography>Disponible</Typography>
      ) : (
        <Typography>{`Réservé par : ${data.booking_status.reserved_by?.username}`}</Typography>
      )}
      <Typography>{`Type d'écran : ${data.monitor_configuration_display}`}</Typography>
      <Typography>{`Nombre d'écran : ${data.number_of_monitors}`}</Typography>
      <Typography>{`Support d'écran : ${
        data.has_laptop_support ? 'Oui' : 'Non'
      }`}</Typography>
      <Typography>{`Possède un dock : ${
        data.has_dock ? 'Oui' : 'Non'
      }`}</Typography>
      <Typography>{`Souris : ${data.has_mouse ? 'Oui' : 'Non'}`}</Typography>
      <Typography>{`Clavier : ${
        data.has_keyboard ? 'Oui' : 'Non'
      }`}</Typography>
      {data.special_attention !== '' && (
        <Typography>{`Autre : ${data.special_attention}`}</Typography>
      )}
    </Box>
  );

  return (
    <Tooltip followCursor title={TooltipInformations()}>
      <Button
        key={data.id}
        sx={deskComponentsStyles(theme).container({
          availability: data.booking_status.is_available,
          selected: deskSelectedState === data,
          isUser: username === data.booking_status.reserved_by?.username,
        })}
        onClick={() => {
          dispatch(setDeskSelected(data));
        }}
      >
        {DeskIcon()}
      </Button>
    </Tooltip>
  );
};

export default DeskComponent;
