import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { helpBoxStyles } from './styles';
import { ReactNode, useState } from 'react';
import useIsTabletPortait from 'src/hooks/useIsTablet';
import CModal from 'src/components/UI/CModal/CModal';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import MonitorIcon from '@mui/icons-material/Monitor';

type HelpBoxProps = {
  setIsReversed: () => void;
};

const HelpBox = ({ setIsReversed }: HelpBoxProps) => {
  const theme = useTheme();
  const isTablet = useIsTabletPortait();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOnClick = () => {
    if (isTablet) {
      setIsModalOpen(true);
    }
  };

  const TooltipInformations = (): ReactNode => (
    <Box>
      <Typography>Code couleur :</Typography>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.success.main,
            width: '15px',
            height: '15px',
          }}
        />
        <Typography>Disponible</Typography>
      </Box>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.error.main,
            width: '15px',
            height: '15px',
          }}
        />
        <Typography>Réservé par un autre utilisateur</Typography>
      </Box>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.info.main,
            width: '15px',
            height: '15px',
          }}
        />
        <Typography>Votre réservation</Typography>
      </Box>
      <Typography sx={{ marginTop: theme.spacing(1) }}>Légende :</Typography>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
      >
        <MonitorIcon />
        <Typography>Écran 24 pouces</Typography>
      </Box>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
      >
        <ScreenshotMonitorIcon />
        <Typography>Écran ultra wide</Typography>
      </Box>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
      >
        <RemoveFromQueueIcon />
        <Typography>Écran incurvé ultra wide</Typography>
      </Box>
    </Box>
  );

  const ModalInformations = (): ReactNode => (
    <CModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      modalTitle={'Aide'}
      error={false}
      errorMessage={''}
      hasCancelButton={false}
    >
      <Box
        sx={{ display: 'flex', gap: theme.spacing(1), flexDirection: 'column' }}
      >
        <Typography>Code couleur :</Typography>
        <Box
          sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.success.main,
              width: '15px',
              height: '15px',
            }}
          />
          <Typography variant="body2">Disponible</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.error.main,
              width: '15px',
              height: '15px',
            }}
          />
          <Typography variant="body2">
            Réservé par un autre utilisateur
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.info.main,
              width: '15px',
              height: '15px',
            }}
          />
          <Typography variant="body2">Votre réservation</Typography>
        </Box>
        <Typography sx={{ marginTop: theme.spacing(1) }}>Légende :</Typography>
        <Box
          sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          <MonitorIcon />
          <Typography variant="body2">Écran 24 pouces</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          <ScreenshotMonitorIcon />
          <Typography variant="body2">Écran ultra wide</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
        >
          <RemoveFromQueueIcon />
          <Typography variant="body2">Écran incurvé ultra wide</Typography>
        </Box>
      </Box>
    </CModal>
  );

  return (
    <Box sx={helpBoxStyles(theme).mainWrapper}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
      >
        <Tooltip title={TooltipInformations()}>
          <HelpIcon color="primary" onClick={() => handleOnClick()} />
        </Tooltip>
        <Tooltip title={"Pivoter l'open space"}>
          <IconButton onClick={() => setIsReversed()}>
            <FlipCameraAndroidIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography sx={{ cursor: 'pointer' }}>
        <Link
          onClick={() => window.open('/Guide utilisateur Flexo.pdf', '_blank')}
        >
          Guide utilisateur
        </Link>
      </Typography>
      {ModalInformations()}
    </Box>
  );
};

export default HelpBox;
