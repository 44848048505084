import { combineReducers } from '@reduxjs/toolkit';
import createBookingSlice from './bookingSlices/createBookingSlice';
import deleteBookingSlice from './bookingSlices/deleteBookingSlice';
import getDeskSlice from './bookingSlices/getDeskSlice';
import getOfficeSpaceSlice from './bookingSlices/getOfficeSpaceSlice';
import deskSelectedSlice from './bookingSlices/deskSelectedSlice';

const bookingReducer = combineReducers({
  createBooking: createBookingSlice,
  deleteBooking: deleteBookingSlice,
  getDesk: getDeskSlice,
  getOfficeSpace: getOfficeSpaceSlice,
  deskSelected: deskSelectedSlice,
});

export default bookingReducer;
