import { Box, Divider, Typography, useTheme } from '@mui/material';
import { deskComponentsStyles } from './styles';
import DeskComponent from './DeskComponent';
import { Desk } from 'src/utils/types/Desk';

type DesksGridProps = {
  data: Desk[];
  isReversed: boolean;
};

const DeskGrid = ({ data, isReversed }: DesksGridProps) => {
  const theme = useTheme();

  const uniqueRows = Array.from(new Set(data.map((desk) => desk.row))).sort(
    (a, b) => a - b,
  );
  const maxColumn = Math.max(...data.map((desk) => desk.column));

  const gridMatrix: (Desk | null)[][] = uniqueRows.map(() =>
    new Array(maxColumn).fill(null),
  );

  data.forEach((desk) => {
    const { row, column } = desk;
    const rowIndex = uniqueRows.indexOf(row);

    if (rowIndex !== -1 && column <= maxColumn) {
      gridMatrix[rowIndex][column - 1] = desk;
    }
  });

  const spacedGrid: JSX.Element[] = [];

  const mirroredGridMatrix = isReversed
    ? gridMatrix.map((row) => [...row.reverse()])
    : gridMatrix;

  mirroredGridMatrix.forEach((row, rowIndex) => {
    spacedGrid.push(
      <Box
        sx={deskComponentsStyles(theme).containerGrid(maxColumn)}
        key={rowIndex}
      >
        {row.map((desk, columnIndex) =>
          desk ? (
            <DeskComponent key={desk.id} data={desk} />
          ) : (
            <Box key={`empty-${rowIndex}-${columnIndex}`} />
          ),
        )}
      </Box>,
    );
  });

  return (
    <Box sx={deskComponentsStyles(theme).wrapper(isReversed)}>
      <Box sx={deskComponentsStyles(theme).gridWrapper(isReversed)}>
        {spacedGrid}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: isReversed ? 'row-reverse' : 'row',
        }}
      >
        <Divider orientation="vertical" />
        <Typography
          sx={{
            textOrientation: 'sideways',
            writingMode: 'vertical-lr',
            transform: isReversed ? 'rotate(-180deg)' : 'unset',
          }}
        >
          Fenêtre
        </Typography>
      </Box>
    </Box>
  );
};

export default DeskGrid;
