import { createSlice } from '@reduxjs/toolkit';
import { ReduxStatus } from 'src/utils/types/reduxStatusValues';
import { getDeskAsync } from '../bookingAsync';
import { GetDeskRequest } from '../types';

const initialState: GetDeskRequest = {
  status: ReduxStatus.Idle,
  error: null,
  alert: {
    successMessage: '',
    errorMessage: '',
  },
  data: [],
};

const getDeskSlice = createSlice({
  name: 'getDesk',
  initialState,
  reducers: {
    resetGetDeskRequest: () => initialState,
    updateItemDeskList: (state, action) => {
      const { Desk, id } = action.payload;
      const newData = [...state.data];

      newData[id] = Desk;
      state.data = newData;
    },
    addItemDeskList: (state, action) => {
      const { Desk } = action.payload;
      const newData = [Desk, ...state.data];

      state.data = newData;
    },
    updateStatusDeskList: (state, action) => {
      const {
        id,
        status,
        name,
        reservation_id,
        image,
      }: {
        id: number;
        status: boolean;
        name?: string;
        reservation_id?: number;
        image?: string;
      } = action.payload;
      const newData = [...state.data];

      const itemIndex = newData.findIndex((item) => item.id === id);

      newData[itemIndex].booking_status.is_available = status;

      if (name && reservation_id && image) {
        newData[itemIndex].booking_status.reserved_by = {
          profile_image: image,
          username: name,
        };
        newData[itemIndex].booking_status.reserved_id = reservation_id;
      } else {
        newData[itemIndex].booking_status.reserved_by = null;
        newData[itemIndex].booking_status.reserved_id = null;
      }

      state.data = newData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeskAsync.pending, (state: { status: string }) => {
        state.status = ReduxStatus.Loading;
      })
      .addCase(getDeskAsync.fulfilled, (state, action) => {
        state.status = ReduxStatus.Succeeded;
        state.alert.successMessage = 'getDesk successful';
        state.data = action.payload;
      })
      .addCase(getDeskAsync.rejected, (state, action) => {
        state.status = ReduxStatus.Failed;
        state.error = action.error.message;
        state.alert.errorMessage = 'getDesk failed';
      });
  },
});

export const {
  resetGetDeskRequest,
  updateItemDeskList,
  addItemDeskList,
  updateStatusDeskList,
} = getDeskSlice.actions;

export default getDeskSlice.reducer;
