import { Box, Divider, Typography, useTheme } from '@mui/material';

import { settingsPageStyles } from './styles';
import { useAppSelector } from 'src/hooks';
import PasswordSection from './PasswordSection';
import AvatarSection from './AvatarSection';
import useIsTabletPortait from 'src/hooks/useIsTablet';

const SettingsPage = () => {
  const theme = useTheme();
  const isTablet = useIsTabletPortait();

  const user = useAppSelector((state) => state.auth.userInfos.data);

  return (
    <Box sx={settingsPageStyles(theme).mainWrapper}>
      <Typography variant="h1">Paramètres</Typography>
      <Box sx={settingsPageStyles(theme).card}>
        <Typography
          sx={settingsPageStyles(theme).title}
          variant="h2"
          color="primary"
        >
          {user.username}
        </Typography>
        <Divider sx={settingsPageStyles(theme).divider} />
        <Box sx={settingsPageStyles(theme).optionsWrapper}>
          <AvatarSection />
          {isTablet && <Divider sx={settingsPageStyles(theme).divider} />}
          <PasswordSection />
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;
