import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createBooking,
  deleteBooking,
  getDesks,
  getOfficeSpace,
} from './bookingAPI';
import {
  CreateBookingPayload,
  GetDeskPayload,
  GetOfficeSpacePayload,
} from './types';

export const createBookingAsync = createAsyncThunk(
  'bookings/createBooking',
  async (body: CreateBookingPayload) => {
    const response = await createBooking(body);

    return response;
  },
);

export const deleteBookingAsync = createAsyncThunk(
  'bookings/deleteBooking',
  async (id: number | null) => {
    const response = await deleteBooking(id);

    return response;
  },
);

export const getDeskAsync = createAsyncThunk(
  'bookings/getDesk',
  async (params?: GetDeskPayload) => {
    const response = await getDesks(
      params?.id,
      params?.booking_date,
      params?.column,
      params?.has_dock,
      params?.has_keyboard,
      params?.has_laptop_support,
      params?.has_mouse,
      params?.monitor_configuration,
      params?.number_of_monitors,
      params?.office_space,
      params?.row,
    );

    return response;
  },
);

export const getOfficeSpaceAsync = createAsyncThunk(
  'bookings/getOfficeSpace',
  async (params?: GetOfficeSpacePayload) => {
    const response = await getOfficeSpace(
      params?.id,
      params?.capacity,
      params?.floor,
      params?.name,
      params?.number_of_employees,
      params?.service,
    );

    return response;
  },
);
