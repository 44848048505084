import axios, { AxiosResponse } from 'axios';
import { BACK_URL } from 'src/config';
import { AuthData, AuthToken } from 'src/utils/types/authData';
import { UserInfos } from 'src/utils/types/UserInfos';
import { store } from '../store';
import { ChangePasswordPayload, UploadAvatarResponse } from './types';
import { NoDataResponse } from 'src/utils/types/redux';

export const login = async (body: AuthData): Promise<AuthToken> => {
  const response = await axios.post<AuthToken>(`${BACK_URL}/auth/token/`, body);

  return response.data;
};
export const logout = async (): Promise<AxiosResponse> => {
  const response = await axios.post<AxiosResponse>(`${BACK_URL}/logout/`);

  return response.data;
};

export const getUserInfos = async (): Promise<UserInfos> => {
  const { token } = store.getState().auth.login;
  const response = await axios.get<UserInfos>(
    `${BACK_URL}/auth/current-user/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const uploadUserImage = async (
  image: File | undefined,
): Promise<UploadAvatarResponse> => {
  const { token } = store.getState().auth.login;
  const formData = new FormData();

  if (image) {
    formData.append('image', image);
  }

  const response = await axios.post<UploadAvatarResponse>(
    `${BACK_URL}/auth/users/update-profile-image-current-user/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  const { token } = store.getState().auth.login;
  const response = await axios.post<NoDataResponse>(
    `${BACK_URL}/auth/reset-password/`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response;
};
